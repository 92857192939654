export default {
  title: 'Minhas notificações',

  empty: 'Nenhuma notificação encontrada',
  search: 'Buscar notificação',

  status_filter: 'Filtrar por status',

  status_options: {
    sent: 'Enviado',
    scheduled: 'Agendado',
    canceled: 'Cancelado',
  },

  table_columns: {
    sent_date: 'Data de envio',
    views: 'Visualizações',
    sent: 'Envios',
    openings: 'Aberturas',
    clicks: 'Link clicks',
  },

  options: {
    new: 'Nova notificação',
    edit: 'Editar notificação',
    cancel: 'Cancelar',
    resend: 'Reenviar',
  },

  resend: {
    title: 'Você deseja reenviar a notificação?',
    description:
      'Essa notificação voltará a ser listada como agendada, e será enviada no horário do agendamento.',
    confirm: 'Sim, quero reenviar',
    cancel: 'Não, quero manter',

    success: {
      title: 'Notificação reenviada!',
      description: 'Essa notificação está ativa novamente.',
    },
  },

  cancel: {
    title: 'Você deseja cancelar a notificação?',
    description:
      'Você ainda poderá reenviar essa notificação caso deseje desfazer essa ação posteriormente.',

    confirm: 'Sim, quero cancelar',
    cancel: 'Não, quero manter',

    success: {
      title: 'Notificação cancelada!',
      description: 'Essa notificação não será mais enviada.',
    },
  },

  confirm: {
    description: {
      scheduled_at: 'Essa notificação será enviada {sendDate}, para {sendTo}.',
    },

    scheduled_at: 'dia {day}',

    format: {
      scheduled_at: "dd 'de' MMMM 'de' yyyy 'ás' HH:mm",
    },

    not: {
      scheduled_at: 'imediatamente',
    },

    send_to:
      '{teamsLength, plural, =0 {todos usuários} =1 {os usuários de um time} other {os usuários de # times}}',
  },
};
